export const staticWinners = [
  {
    name: "Matrix", // business name
    logo: require("@static/businessLogos/matrix.png"), // business logo
    winners: {
      main: {
        name: "Shreya Jain",
        address: "Ontario, Canada",
        prize: "$100",
      },
      mentions: [],
      items: [],
    },
  },
  {
    name: "Max Schulte: Teacher and Art Interventionist", // business name
    logo: require("@static/businessLogos/MaxSchulteInteractiveMediaArtist.png"), // business logo
    winners: {
      main: {},
      mentions: [],
      items: [],
    },
  },
  {
    name: "Meridian Stories", // business name
    logo: require("@static/businessLogos/meridianstories.png"), // business logo
    winners: {
      main: {},
      mentions: [],
      items: [],
    },
  },
  {
    name: "Knowledgeflow Foundation", // business name
    logo: require("@static/businessLogos/KnowledgeFlow.png"), // business logo
    winners: {
      main: {
        name: "Sofia Xu",
        address: "United States",
        prize: "$100",
      },
      mentions: [],
      items: [],
    },
  },
  {
    name: "Energie Academie", // business name
    logo: require("@static/businessLogos/ENERGIACADEMIE.png"), // business logo
    winners: {
      main: {},
      mentions: [],
      items: [],
    },
  },
  {
    name: "Porter Airlines", // business name
    logo: require("@static/businessLogos/Porter.jpg"), // business logo
    winners: {
      main: {},
      mentions: [],
      items: [],
    },
  },
  {
    name: "Others", // business name
    logo: require("@static/eventResults/img_08-others.png"), // business logo
    items: [
      {
        logo: require("../../static/raffle/items/UW_hoodie.png"),
        itemName: "Hoodie (black)",
        partnerName: "Waterloo University",
        partnerLogo: require("../../static/raffle/partners/waterloouni.png"),
        winners: [
          {
            name: "Joanna",
            country: "United States",
            region: "Michigan",
          },
          {
            name: "Sofia Xu",
            country: "United States",
            region: "",
          },
          {
            name: "Noah",
            country: "Japan",
            region: "Kyōto Prefecture",
          },
        ],
      },
      {
        logo: require("../../static/raffle/items/savethefrogwhite.png"),
        itemName: "T-Shirt",
        partnerName: "SavetheFrogs!",
        partnerLogo: require("../../static/raffle/partners/savetheFrogs.png"),
        winners: [
          {
            name: "Ginny Feng",
            country: "United States",
            region: "Tennessee",
          },
        ],
      },
      {
        logo: require("../../static/raffle/items/ORGANICCOTTONU-SACU-Do-U(Black&White).png"),
        itemName: "Cotton Shoulder Sling",
        partnerName: "building-U",
        partnerLogo: require("../../static/raffle/partners/building-U.png"),
        winners: [
          {
            name: "Lena",
            country: "United States",
            region: "Michigan",
          },
          {
            name: "Destiny Arroyo",
            country: "United States",
            region: "California",
          },
          {
            name: "Joanna",
            country: "United States",
            region: "Michigan",
          },
        ],
      },
    ],
  },
];
